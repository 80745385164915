import React from "react"
import Layout from "../components/layout"
import "../styles/forms.css"
import SEO from "../components/seo"

import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

export default function Giveaway() {
  const data = useStaticQuery(graphql`
    query {
      giveawaypic: file(relativePath: { eq: "photos/300Webbanner.jpg" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1080) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Layout title="300 IG Followers!">
      <SEO title="300 IG Follower Giveaway." />
      <div className="miniHero">
        <Img
          fluid={data.giveawaypic.childImageSharp.fluid}
          alt="Promo Image for 300 Follwer Giveaway - You found the hidden giveaway."
        />
      </div>
      <section className="two-col">
        <div className="two-col-child">
          <h1>You found it!</h1>
          <h2>300 IG Follower Givaway</h2>
          <p>
            Creative Box hit 300 followers! Which means it's time to celebrate
            this milestone.
          </p>
          <p>
            If you are reading this you have found the 300 Follower Giveaway. Be
            you are the first 3 people to direct message (DM) @crtvbox.co or
            email hellocreativebox@gmail.com with the phrase “Think inside the
            box”. You’’ll get a 30% discount on your next creative box gig.
          </p>
          <p>A huge Thank you, to all of the OG IG followers.</p>
        </div>
      </section>
    </Layout>
  )
}
